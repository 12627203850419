import doubleArrowDown from '../../assets/images/double-arrow-down.png'
import fonoFrancisApresentacao from '../../assets/images/foto-francis-cortada.jpg'
import styles from './PaginaApresentacao.module.scss'
import cx from 'classnames'

const PaginaApresentacao = () => (
  <section className={cx(styles.pageCard, styles.backgroundNotebook)}>
    <section className={styles.firstBlock}>
      <section className={styles.text}>
        <h1>
          Fonoaudiologia Online Infantil
        </h1>
        <p>
          Precisa de atendimento fonoaudiológico para sua criança?
        </p>
        <p>
          Agende uma entrevista gratuita com a fono online! Clique no ícone do Whatsapp ao lado.
        </p>
      </section>
      <div className={styles.imagemComAssinatura}>
        <div className={styles.portrait}>
          <img src={fonoFrancisApresentacao} />
        </div>
        <div className={styles.nomeDaFono}>
          <p>
            Fonoaudióloga
          </p>
          <p>
            Francis Guimarães
          </p>
          <p>
            CRFa 8- 12521
          </p>
        </div>
        
        <img src={doubleArrowDown} alt="Menu Bar" />
      </div>
    </section>
    <section className={styles.secondBlock}>
    <iframe width="600" height="380"
      src="https://www.youtube.com/embed/BdSosOWeVpE?si=yB3OPBMeuPK4w7cr">
    </iframe> 
    </section>
  </section>
)

export default PaginaApresentacao