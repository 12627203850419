import styles from './PaginaAreasAtuacao.module.scss'
import cx from 'classnames'

const PaginaAreasAtuacao = () => (
  <section className={styles.pageCardBgWhite}>
    <section className={styles.headerAreaAtuacao}>
      <div className={styles.text}>
        <span>Áreas de atuação</span>
      </div>
    </section>
    <section className={styles.cardsContainerAtuacao}>
      <div className={styles.cardAtuacaoContainer}>
        <div className={cx(styles.green, styles.pill)}>
          <span>Transtornos dos sons da fala</span>
        </div>
        <div className={cx(styles.cardAtuacao, styles.greenBorder)}>
          <span>Diagnóstico e tratamento de dificuldades na produção de sons da fala, como substituições, omissões ou distorções, visando melhorar a comunicação oral.</span>
        </div>
      </div>
      <div className={styles.cardAtuacaoContainer}>
        <div className={cx(styles.purple, styles.pill)}>
          <span>Alterações da fluência da fala</span>
        </div>
        <div className={cx(styles.cardAtuacao, styles.purpleBorder)}>
          <span>Avaliação e intervenção em distúrbios de fluência, como gagueira e disfluências, para promover uma fala mais fluente e eficaz.</span>
        </div>
      </div>
      <div className={styles.cardAtuacaoContainer}>
        <div className={cx(styles.green, styles.pill)}>
          <span>Motricidade orofacial</span>
        </div>
        <div className={cx(styles.cardAtuacao, styles.greenBorder)}>
          <span>Tratamento de problemas na musculatura e estrutura oral, como dificuldades na mastigação, deglutição e articulação dos sons, para melhorar sua funcionalidade e coordenação.</span>
        </div>
      </div>
      <div className={styles.cardAtuacaoContainer}>
        <div className={cx(styles.purple, styles.pill)}>
          <span>Consultoria fonoaudiológica</span>
        </div>
        <div className={cx(styles.cardAtuacao, styles.purpleBorder)}>
          <span>Orientações para mães de crianças até três anos que estão enfrentando dificuldades na comunicação inicial, fornecendo suporte em questões relacionadas ao desenvolvimento da linguagem.</span>
        </div>
      </div>
    </section>
  </section>
)

export default PaginaAreasAtuacao