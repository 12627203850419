import rovena from '../../assets/images/Rovena.jpeg'
import erica from '../../assets/images/erica-foto-nova.png'
import bianca from '../../assets/images/Bianca.jpg'
import styles from './PaginaReferencias.module.scss'
import cx from 'classnames'

const PaginaBeneficios = () => (
  <section className={styles.pageCardBgWhite}>
    <section className={styles.headerBeneficios}>
      <div className={styles.text}>
        <p>Depoimentos de mães de pacientes</p>
      </div>
    </section>
    <section className={styles.cardsContainer}>
      <div className={styles.cardContainer}>
        <div className={cx(styles.cardBeneficios, styles.purpleBorder)}>
          <div className={cx(styles.circularIcon,  styles.purple)}>
            <img src={erica} alt="" />
          </div>
          <h3>Érica, mãe do José</h3>
          <span>Quando meu filho iniciou a terapia com a Francis ele se quer pronunciava uma frase de forma que fosse possível entender e hoje, apesar de termos um longo caminho pela frente, ele consegue se comunicar muito bem. <br/> A cada sessão notamos seu progresso e ficamos com o coração mais leve. Poder fazer as sessões sem sair de casa também foi um fator importante para prosseguirmos com a terapia.</span>
          <span>Profissional excelente e confiável, nos ajudou muito, serei eternamente grata por toda sua paciência, seu carinho e dedicação ao nosso pequeno.</span>
        </div>
      </div>
      <div className={styles.cardContainer}>
        <div className={cx(styles.cardBeneficios, styles.purpleBorder)}>
          <div className={cx(styles.circularIcon, styles.purple)}>
            <img src={rovena} alt="" />
          </div>
          <h3>Rovena, mãe do Nícolas</h3>
          <span>Quem diria que tratamento com fonoaudióloga on-line seria possível? Pois bem, é possível e super válido! Meu filho faz acompanhamento com a Tia Francis a quase 1 ano, e a evolução dele é uma coisa linda de ver, o método utilizado nas sessões são dinâmicos, lúdicos, a criança aprende brincando. Nicolas faz a aula com gosto e prazer. Sou muito grata ao trabalho dela, ao carinho dela com ele e conosco. Super indico e aprovo o trabalho da Tia Francis. A evolução do meu filho é nítida e muito gratificante! Muito obrigada mesmo Tia Francis pelo lindo trabalho.</span>
        </div>
      </div>
      <div className={styles.cardContainer}>
        <div className={cx(styles.cardBeneficios, styles.purpleBorder)}>
          <div className={cx(styles.circularIcon, styles.purple)}>
            <img src={bianca} alt="" />
          </div>
          <h3>Bianca, mãe da Diana</h3>
          <span>Estamos na 29° sessão e a Diana gosta muito de participar, as sessões são dinâmicas, Tia Francis tem jogo de cintura quando minha filha apresenta comportamento de rigidez - possui TEA - sempre busca utilizar de seu hiperfoco para que possam realizar as atividades com o melhor aproveitamento.</span>
          <span>Mesmo de longe sentimos a sua dedicação, profissionalismo e amor para com os pacientes. Francis é Amável e paciente, estamos em um processo contínuo de evolução da fala da nossa Didis. Gratidão.</span>
        </div>
      </div>
    </section>
  </section>
)

export default PaginaBeneficios