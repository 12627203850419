import styles from './index.module.scss'
import IconeZapZap from './IconeZapZap/IconeZapZap'
import PaginaInicial from './PaginaInicial/PaginaInicial'
import PaginaApresentacao from './PaginaApresentacao/PaginaApresentacao'
import PaginaBeneficios from './PaginaBeneficios/PaginaBeneficios'
import PaginaAreasAtuacao from './PaginaAreasAtuacao/PaginaAreasAtuacao'
import PaginaReferencias from './PaginaReferencias/PaginaReferencias'
import PaginaRealizarSessao from './PaginaRealizarSessao/PaginaRealizarSessao'
import PaginaProposito from './PaginaProposito/PaginaProposito'

const LandingPage = () => {
  return (
    <section className={styles.mainBody}>
      <section className={styles.wpp}>
        <IconeZapZap />
      </section>
      {/* <PaginaInicial /> */}
      <PaginaApresentacao />
      <PaginaAreasAtuacao />
      <PaginaBeneficios />
      <PaginaReferencias />
      {/* <PaginaRealizarSessao /> */}
      <PaginaProposito />
    </section>
  )
}

export default LandingPage