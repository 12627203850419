import fotoCircular from '../../assets/images/DSC_3305.jpg'
import styles from './PaginaProposito.module.scss'
import cx from 'classnames'

const PaginaProposito = () => (
  <section className={styles.pageCardBgWhite}>
    <section className={styles.quemSouEuContainer}>
      <section className={styles.cardQuemSouEu}>
        <h2>
          Quem sou eu e qual o meu propósito?
        </h2>
        <label className={styles.text}>
        Sou fonoaudióloga, formada com láurea acadêmica pela Universidade Potiguar, no Rio Grande do Norte, e atualmente pós-graduanda em Distúrbios da Fala e Linguagem. Também sou mãe de uma tagarelinha e, no meu dia a dia, estou constantemente interagindo com crianças para ajudá-las a se comunicarem melhor. Desde a minha formação, busco aperfeiçoamento contínuo nas minhas áreas de atuação, o que fortalece minha prática. Trabalho com um olhar sensível e técnico, focado em compreender e desenvolver o potencial único de cada criança. Meu compromisso é oferecer um atendimento especializado e acolhedor, onde as crianças se sintam seguras para explorar a comunicação, e as famílias, confiantes no processo.
        </label>
      </section>
      <section className={cx(styles.cardQuemSouEu, styles.containerSegundoBloco)}>
        <div className={styles.fotoContainer}>
          <img src={fotoCircular} alt="" />
        </div>
      </section>
    </section>
  </section>
)

export default PaginaProposito